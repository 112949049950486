<template>
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="120px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="库存期间">
              <el-date-picker
                v-model="form.inventoryPeriod"
                value-format="yyyy-MM"
                type="month"
                placeholder="请选择"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="货主">
              <el-select v-model="form.cargoOwnerCode" :placeholder="$t('page.selectPlaceholder')" filterable clearable>
                <el-option
                  v-for="item in cargoOwnerList"
                  :key="item.logicWarehouseCode"
                  :value="item.cargoOwnerCode"
                  :label="item.cargoOwnerName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="逻辑仓名称">
              <el-select v-model="form.logicWarehouseCode" :placeholder="$t('page.selectPlaceholder')" filterable clearable>
                <el-option
                  v-for="item in warehouseList"
                  :key="item.logicWarehouseCode"
                  :value="item.logicWarehouseCode"
                  :label="item.logicWarehouseName"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-row :span="24" class="mb-3" style="display: flex">
          <el-button type="primary" @click="openExAdd">新增出库单</el-button>
          <el-button type="primary" style="margin-left: 10px;" @click="openSystem">
            导入第三方系统出库单
          </el-button>
        </el-row> -->

      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick(1)">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-table
      ref="tableData"
      v-loading="loading"
      class="mb-3"
      :data="tableData"
      :header-cell-style="{background:'#fafafa'}"
      max-height="550px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="cargoOwnerCode" label="货主" align="center" />
      <el-table-column prop="logicWarehouseName" label="逻辑仓名称" align="center" />
      <el-table-column prop="inventoryPeriod" label="库存期间" align="center" />
      <el-table-column prop="calcStatus" label="计算结果" align="center">
        <template slot-scope="scope">{{ getCalcStatusLabel(scope.row.calcStatus) }}</template>
      </el-table-column>
      <el-table-column prop="calcReason" label="失败原因" align="center" />
      <el-table-column
        align="center"
        fixed="right"
        :label="$t('page.operate')"
        width="150"
      >
        <template slot-scope="scope">
          <!-- 查看 -->
          <span v-if="scope.row.calcStatus == 0">
            <el-button type="text" size="small" @click="handleRelease(scope.row.id)">重新计算</el-button>
          </span>
          <span v-if="scope.row.calcStatus == 0">
            <!-- 查看 -->
            <router-link :to="{ append:true, path: 'details', query: { row:JSON.stringify(scope.row) }}">
              <el-button type="text" size="small">查看</el-button>
            </router-link>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <Paging :pager="pager" @update="update" />

  </div>
</template>
<script>
import { pageResult, recalculate } from '@/api/inventory-result'
import { getCargoOwner } from '@/api/listSelection'

import { getWarehouse } from '@/api/stockin'
import Paging from '@/components/Paging'
import { Mixin } from '@/mixin/mixin.js'

export default {
  name: '',
  components: {
    Paging
  },
  mixins: [Mixin],
  data() {
    return {
      warehouseList: [],
      tableData: [],
      form: {
        cargoOwnerCode: '',
        inventoryPeriod: '',
        logicWarehouseCode: ''
      },
      showRow: false, // 切换显示input
      loading: false,
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      cargoOwnerList: []
    }
  },
  computed: {
    queryParams() {
      const { inventoryPeriod, cargoOwnerCode, logicWarehouseCode } = this.form
      return Object.assign({}, this.pager, { inventoryPeriod, cargoOwnerCode, logicWarehouseCode })
    }

  },

  mounted() {
    this._pageResult()
    this._queryWarehouseList()
    this._getCargoOwner()
  },
  methods: {
    queryClick() {
      this.pager.current = 1
      this._pageResult()
    },
    resetClick() { // 点击重置清空文本框信息
      Object.assign(this.form, this.$options.data.call(this).form)
      this._pageResult()
    },
    showRowClick() { // 点击展开控制文本框的显示隐藏
      this.showRow = !this.showRow
    },

    async _pageResult(flag) {
      try {
        this.loading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { datas: { pager, records }} = await pageResult(this.queryParams)
        this.pager = pager
        this.tableData = records
      } finally {
        this.loading = false
      }
    },
    // 终止出库
    handleRelease(id) {
      this.$confirm('确定要重新计算结果', this.$t('page.Prompt'), {
        confirmButtonText: this.$t('page.sure'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning'
      }).then(async() => {
        console.log(id)
        await recalculate(id)
        this._pageResult()
        this.$message({
          type: 'success',
          message: '操作成功'
        })
      })
    },
    update(val) {
      this.pager = val
      this._pageResult()
    },
    async _queryWarehouseList() {
      const { datas } = await getWarehouse()
      this.warehouseList = datas
    },
    async _getCargoOwner() {
      const { datas } = await getCargoOwner()
      this.cargoOwnerList = datas
    }

  }
}
</script>

<style lang="scss" scoped>

.add-dialog {
  /deep/ .el-input {
      margin-bottom: 10px;
    }
}
.dial_inp {
  display: flex;
  flex-wrap: wrap;
  > div:nth-child(2n) {
    margin-left: 10px;
  }
  > div {
    width: 216px;
  }
}
</style>

